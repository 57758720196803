/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from 'clsx'
import {FC} from 'react'
// import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { useButtonClick, useListView } from '../../core/ListViewProvider'
import { Link } from 'react-router-dom'


type Props = {
  id?: number,
  log: string,
  isLink?: boolean
}

const CallLogsInfoCell: FC<Props> = ({log, isLink, id}) => {
  console.log(log, isLink, id);
  const { setItemIdForUpdate } = useListView()
  const {setButtonName} = useButtonClick()
  
  const openEditModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setItemIdForUpdate(id)    
    setButtonName('View')
  }
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    
    <div className='d-flex flex-column'>
    {/* {isLink && <a href='#' className='text-gray-800 text-hover-primary mb-1' onClick={openEditModal}>{log}</a>} */}
    {isLink && <Link to={'/customers/subscribed/overview/' + id} target="_blank" rel="noopener noreferrer"><span>{log}</span></Link>}
    {!isLink && <span>{log}</span>}
    </div>
  </div>
)}

export {CallLogsInfoCell}
