import {Column} from 'react-table'
import {CallLogsInfoCell} from './CallLogInfoCell'
import {CallLogCustomHeader} from './CallLogCustomHeader'

import {CallLog} from '../../core/_models'


const callLogsColumns: ReadonlyArray<Column<CallLog>> = [
  // {
  //   Header: (props) => <CallLogCustomHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CallLogsInfoCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <CallLogCustomHeader tableProps={props} title='user name' className='min-w-125px' />,
    id: 'user_name',
    Cell: ({...props}) => <CallLogsInfoCell log={props.data[props.row.index].user_name} />,
  },
  {
    Header: (props) => <CallLogCustomHeader tableProps={props} title='opponents name' className='min-w-125px' />,
    id: 'opponents_name',
    Cell: ({...props}) => <CallLogsInfoCell log={props.data[props.row.index].opponents_name} isLink={true} id={props.data[props.row.index].opponents_id} />,
  },
  {
    Header: (props) => <CallLogCustomHeader tableProps={props} title='status' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <CallLogsInfoCell log={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => <CallLogCustomHeader tableProps={props} title='type' className='min-w-125px' />,
    id: 'type',
    Cell: ({...props}) => <CallLogsInfoCell log={props.data[props.row.index].type} />,
  },
  {
    Header: (props) => <CallLogCustomHeader tableProps={props} title='time' className='min-w-125px' />,
    id: 'time',
    Cell: ({...props}) => <CallLogsInfoCell log={props.data[props.row.index].time} />,
  },

  // {
  //   Header: (props) => <CallLogsCustomHeader tableProps={props} title='Order Id' className='min-w-125px' />,
  //   id: 'order_id',
  //   Cell: ({...props}) => <CallLogsInfoCell order_id={props.data[props.row.index].order_id} />,
  // },

  // {
  //   Header: (props) => <CallLogsCustomHeader tableProps={props} title='Expire Date' className='min-w-125px' />,
  //   id: 'expire_date',
  //   Cell: ({...props}) => <CallLogsExpireDateCell expire_date={props.data[props.row.index].expire_date} />,
  // },

  // {
  //   Header: (props) => <CallLogsCustomHeader tableProps={props} title='Transaction Date' className='min-w-125px' />,
  //   id: 'transaction_date',
  //   Cell: ({...props}) => <CallLogsTransactionDateCell transaction_date={props.data[props.row.index].transaction_date} />,
  // },

  // {
  //   Header: (props) => <CallLogsCustomHeader tableProps={props} title='amount' className='min-w-125px' />,
  //   id: 'amount',
  //   Cell: ({...props}) => <CallLogsAmountDateCell plan={props.data[props.row.index]} />,
  // },
  
  // {
  //   Header: (props) => (
  //     <EducationCustomHeader tableProps={props} title='Country' className='min-w-125px' />
  //   ),
  //   id: 'country_name',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].country.country_name} />,
  // },


  // {
  //   Header: (props) => (
  //     <EducationCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <CallLogsCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <CallLogsActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {callLogsColumns}
