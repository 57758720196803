import axios, { AxiosResponse } from 'axios'
import { type } from 'os'
import { Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_PLAN_REPORT = `${API_URL}/dashboard/plan/report`

export const DASHBOARD_SALES_REPORT = `${API_URL}/dashboard/sales/report`

export const DASHBOARD_USER_REPORT = `${API_URL}/dashboard/user/report`

export const DASHBOARD_TODAY_SUBSCRIPTION = `${API_URL}/dashboard/today_subscription/report`

export const DASHBOARD_CUSTOMER_REPORT = `${API_URL}/dashboard/subscription/report`

export const DASHBOARD_GENDER_REPORT = `${API_URL}/dashboard/gender/report`

export const DASHBOARD_REVENUE_REPORT = `${API_URL}/dashboard/sales/report`

export const DASHBOARD_SURVEY_REPORT = `${API_URL}/survey_count`

export const DASHBOARD_SUPPORT_REPORT = `${API_URL}/support_Count`

export const DASHBOARD_MONTHLY_REPORT = `${API_URL}/dashboard/monthly_subscription/report`

export const DASHBOARD_MONTHLY_SALES_REPORT = `${API_URL}/dashboard/monthly_revenue/report`

export type PlanReportResponse = { month: string, count: number }

export type SalesReportResponse = { month: string, total: number }

export type UserReportResponse = { count: number }

export type TodaySubscriptionResponse = { count: number }

export type TotalCustomerSubscriptionResponse = { count: number }

export type TotalgenderResponse = { count: number }

export type TotalRevenueResponse = { count: number }

export type TotalSurveyResponse = { count: number }

export type TotalSupportResponse = { count: number }

export type MonthlySalesResponse = { count: number }

export type MonthlySubscriptionResponse = { count: number }



export const getPlanReport = (): Promise<{ userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_PLAN_REPORT}`)
    .then((response: AxiosResponse<Response<{ userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number }>>) => response.data)
    .then((response: Response<{ userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number }>) => response.result)
}

export const getSalesReport = (): Promise<{ salesListReportInfo: SalesReportResponse[], totalSales: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_SALES_REPORT}`)
    .then((response: AxiosResponse<Response<{ salesListReportInfo: SalesReportResponse[], totalSales: number }>>) => response.data)
    .then((response: Response<{ salesListReportInfo: SalesReportResponse[], totalSales: number }>) => response.result)
}

export const getUserReport = (): Promise<{ userListReportInfo: UserReportResponse[], totalRecordCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_USER_REPORT}`)
    .then((response: AxiosResponse<Response<{ userListReportInfo: UserReportResponse[], totalRecordCount: number }>>) => response.data)
    .then((response: Response<{ userListReportInfo: UserReportResponse[], totalRecordCount: number }>) => response.result)
}

export const getTodaySubscription = (): Promise<{ userTodayListReportInfo: TodaySubscriptionResponse[], todaydate: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_TODAY_SUBSCRIPTION}`)
    .then((response: AxiosResponse<Response<{ userTodayListReportInfo: TodaySubscriptionResponse[], todaydate: number }>>) => response.data)
    .then((response: Response<{ userTodayListReportInfo: TodaySubscriptionResponse[], todaydate: number }>) => response.result)
}

export const getTotalCustomerSubscriptionReport = (): Promise<{ totalcustomerSubscriptionListReportInfo: TotalCustomerSubscriptionResponse[], totalCustomerSubscriptionCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_CUSTOMER_REPORT}`)
    .then((response: AxiosResponse<Response<{ totalcustomerSubscriptionListReportInfo: TotalCustomerSubscriptionResponse[], totalCustomerSubscriptionCount: number }>>) => response.data)
    .then((response: Response<{ totalcustomerSubscriptionListReportInfo: TotalCustomerSubscriptionResponse[], totalCustomerSubscriptionCount: number }>) => response.result)
}

export const getFemaleGenderReport = (): Promise<{ FemaleGenderListReportInfo: TotalCustomerSubscriptionResponse[], femaleCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_GENDER_REPORT}`)
    .then((response: AxiosResponse<Response<{ FemaleGenderListReportInfo: TotalgenderResponse[], femaleCount: number }>>) => response.data)
    .then((response: Response<{ FemaleGenderListReportInfo: TotalgenderResponse[], femaleCount: number }>) => response.result)
}
export const getMaleGenderReport = (): Promise<{ MaleGenderListReportInfo: TotalCustomerSubscriptionResponse[], maleCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_GENDER_REPORT}`)
    .then((response: AxiosResponse<Response<{ MaleGenderListReportInfo: TotalgenderResponse[], maleCount: number }>>) => response.data)
    .then((response: Response<{ MaleGenderListReportInfo: TotalgenderResponse[], maleCount: number }>) => response.result)
}

export const getAllUserReport = (): Promise<{ AllUserListReportInfo: UserReportResponse[], allUserCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_USER_REPORT}`)
    .then((response: AxiosResponse<Response<{ AllUserListReportInfo: UserReportResponse[], allUserCount: number }>>) => response.data)
    .then((response: Response<{ AllUserListReportInfo: UserReportResponse[], allUserCount: number }>) => response.result)
}

export const getRevenueReport = (): Promise<{ RevenueListReportInfo: TotalRevenueResponse[], totalSales: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_REVENUE_REPORT}`)
    .then((response: AxiosResponse<Response<{ RevenueListReportInfo: TotalRevenueResponse[], totalSales: number }>>) => response.data)
    .then((response: Response<{ RevenueListReportInfo: TotalRevenueResponse[], totalSales: number }>) => response.result)
}

export const getSurveyReport = (): Promise<{ SurveyListReportInfo: TotalSurveyResponse[], totalRecordCount: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_SURVEY_REPORT}`)
    .then((response: AxiosResponse<Response<{ SurveyListReportInfo: TotalSurveyResponse[], totalRecordCount: number }>>) => response.data)
    .then((response: Response<{ SurveyListReportInfo: TotalSurveyResponse[], totalRecordCount: number }>) => response.result)
}

export const getSupportReport = (): Promise<{ SupportListReportInfo: TotalSupportResponse[], result: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_SUPPORT_REPORT}`)
    .then((response: AxiosResponse<Response<{ SupportListReportInfo: TotalSupportResponse[], result: number }>>) => response.data)
    .then((response: Response<{ SupportListReportInfo: TotalSupportResponse[], result: number }>) => response.result)
}

export const getSaleseport = (): Promise<{ MonthlySalesListReportInfo: MonthlySalesResponse[], totalMonthlySales: number } | undefined> => {
  return axios
    .get(`${DASHBOARD_MONTHLY_SALES_REPORT}`)
    .then((response: AxiosResponse<Response<{ MonthlySalesListReportInfo: MonthlySalesResponse[], totalMonthlySales: number }>>) => response.data)
    .then((response: Response<{ MonthlySalesListReportInfo: MonthlySalesResponse[], totalMonthlySales: number }>) => response.result)
}

export const getMonthlyReport = (): Promise<{ MonthlyPlanReportInfo: MonthlySubscriptionResponse[], userMonthlyPlanList: number } | undefined> => {
  // console.log('getMonthlyReport>>>>');

  return axios
    .get(`${DASHBOARD_MONTHLY_REPORT}`)
    .then((response: AxiosResponse<Response<{ MonthlyPlanReportInfo: MonthlySubscriptionResponse[], userMonthlyPlanList: number }>>) => response.data)
    .then((response: Response<{ MonthlyPlanReportInfo: MonthlySubscriptionResponse[], userMonthlyPlanList: number }>) => response.result)
}
