import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TotalSurveyResponse, TotalSupportResponse, getSurveyReport, getSupportReport } from '../core/_requests';

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const Survey: React.FC<Props> = ({ className }) => {
  const [SurveyListReportInfo, setTotalSurveyReportInfo] = useState<{ SurveyListReportInfo: TotalSurveyResponse[], totalRecordCount: number }>()
  console.log('SurveyListReportInfo>>>>>>>:::>>', SurveyListReportInfo);

  const getTotalSurveyPlanReport = async () => {
    const totalSurveyReport = await getSurveyReport()

    console.log('totalSurveyReport', totalSurveyReport)
    totalSurveyReport && setTotalSurveyReportInfo(totalSurveyReport)
  }

  useEffect(() => {
    getTotalSurveyPlanReport()
  }, [])

  const [SupportListReportInfo, setTotalSupportReportInfo] = useState<{ SupportListReportInfo: TotalSupportResponse[], result: number }>()
  console.log('SupportListReportInfo>>>', SupportListReportInfo);

  const getTotalSupportPlanReport = async () => {
    const totalSupportReport = await getSupportReport()

    console.log('totalSupportReport', totalSupportReport)
    totalSupportReport && setTotalSupportReportInfo(totalSupportReport)
  }

  useEffect(() => {
    getTotalSupportPlanReport()
  }, [])

  return (
    <Container className='w-lg-980px bg-white rounded shadow-sm m-5 p-lg-15 mx-auto'>
      <div className={`card ${className}`}>
        <div className='me-2'>
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            Support & Survey
          </a>
        </div>
      </div>
      <Row className='border border border-gray-400 border-dashed rounded bg-white rounded shadow-sm' style={{ border: '1px solid white', minHeight: "150px", maxWidth: '420px' }}>
        <Col className='col ' xl={6} md={6} sm={12} xxl={12}>
          <div className='border p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Survey Count</h2>
              <div className=''>
                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>{SurveyListReportInfo?.totalRecordCount}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6} sm={12} xxl={12}>
          <div className='border p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Support Count</h2>
              <div className=''>
                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>{SupportListReportInfo?.result}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container >
  );
}

export { Survey }