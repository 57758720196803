import {ButtonClickProvider, ListViewProvider, useButtonClick, useListView} from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { CallLogsListHeader } from './components/header/CallLogsListHeader'
import { CallLogsTable } from './table/user/CallLogsTable'
import { KTCard } from '../../../../../_metronic/helpers'
import { FC } from 'react'
import { Navigate } from 'react-router-dom'
const CallLogsList = () => {
  const {itemIdForUpdate} = useListView()
  const {buttonName} = useButtonClick()
  console.log('itemIdForUpdate', itemIdForUpdate, buttonName);
  
  return (
    <>
      <KTCard>
        <CallLogsListHeader />
        <CallLogsTable />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && buttonName === 'View' && <Navigate to={'/customers/subscribed/overview/' + itemIdForUpdate} />} */}
    </>
  )
}

type Props = {
  userId: string
}

const CallLogsListListWrapper: FC<Props> = ({userId}) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider userId={userId}>
        <ListViewProvider>
          <CallLogsList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export { CallLogsListListWrapper }
