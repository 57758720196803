const QUERIES = {
  USERS_LIST: 'users-list',
  DRINK_LIST: 'drink-list',
  EDUCATIONS_LIST: 'educations-list',
  COUPONS_LIST: 'coupons-list',
  ADVERTISEMENTS_LIST: 'advertisements-list',
  CITY_LIST: 'city-list',
  ABOUTYOURSELFTEMPLATE_LIST: 'aboutYourSelfTemplate-list',
  GENDER_LIST:'gender-list',

  CHILDREN_LIST:'children-list',

  
  ROLES_LIST: 'roles-list',
  PERMISSIONS_LIST: 'permissions-list',
  ACTIVE_LIST:'active-list',
  SEXUAL_LIST:'sexual-list',
  PROOF_LIST: 'proof-list',
  RELIGIONS_LIST: 'religions-list',
  LANGUAGE_LIST: 'language-list',
  RELATIONSHIP_LIST: 'relationship-list',
  ADMIN: 'admin',
  USER_SUBSCRIPTION: 'user-subscription-list',
  USER_CALL_LOGS: 'user-call-logs-list',
  CMS_LIST: 'cms-list',
  PACKAGE_LIST: 'package-list',
  PLAN_LIST: 'plan-list',
  STATE_LIST: 'state-list',
  PUSH_NOTIFICATION_LIST: 'pushNotification-list',
  SUCCESSSTORIES_LIST: 'successStories-list',
  PAYMENTS_LIST: 'payments-list',


  RELIGION_LIST: 'religion-list',


  SURVEY_LIST: 'survey-list',
  MARITAL_LIST: 'marital-list',
  COUNTRY_LIST: 'country-list',
  STAFF_LIST: 'staff-list',
  

  CATEGORY_LIST: 'categories-list',
  SUB_CATEGORY_LIST: 'sub-categories-list',

  PAYMENT_GATEWAY_LIST: 'payment-gateway-list',
}

export { QUERIES }
