import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MonthlySubscriptionResponse, getMonthlyReport, MonthlySalesResponse, getSaleseport } from '../core/_requests';


type Props = {
  className: string
  chartColor: string
  chartHeight: string
}



const MonthReport: React.FC<Props> = ({ className }) => {

  const [MonthlyPlanReportInfo, setMonthlyReportInfo] = useState<{ MonthlyPlanReportInfo: MonthlySubscriptionResponse[], userMonthlyPlanList: number }>()
  console.log('MonthlyPlanReportInfo>>>>>>>:::>>', MonthlyPlanReportInfo);

  // let month = userMonthlyPlanList?.map((count))
  // console.log('month>>>>>>',month);

  const getMonthlyPlanReport = async () => {
    const monthlyReport = await getMonthlyReport()

    console.log('monthlyReport', monthlyReport)
    monthlyReport && setMonthlyReportInfo(monthlyReport)
  }

  useEffect(() => {
    getMonthlyPlanReport()
  }, [])

  const [MonthlySalesListReportInfo, setMonthlySalesReportInfo] = useState<{ MonthlySalesListReportInfo: MonthlySalesResponse[], totalMonthlySales: number }>()

  const getMonthlySalesReport = async () => {
    const totalMonthlySalesReport = await getSaleseport()

    console.log('totalMonthlySalesReport', totalMonthlySalesReport)
    totalMonthlySalesReport && setMonthlySalesReportInfo(totalMonthlySalesReport)
  }

  useEffect(() => {
    getMonthlySalesReport()
  }, [])

  return (
    <Container className='w-lg-980px bg-white rounded shadow-sm  p-lg-15 mx-auto'>
      <div className={`card ${className}`}>
        <div className='me-2'>
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            Current Month Details
          </a>
        </div>
      </div>
      <Row className='border border border-gray-400 border-dashed rounded bg-white rounded shadow-sm' style={{ border: '1px solid white', minHeight: "150px", maxWidth: '420px' }}>
        <Col className='col ' xl={6} md={6} sm={12} xxl={12}>
          <div className='border p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Current Month Subscription</h2>
              <div className=''>
                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>{MonthlyPlanReportInfo?.userMonthlyPlanList}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={6} md={6} sm={12} xxl={12}>
          <div className='border p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Current Month Revenue</h2>
              <div className=''>
                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>${MonthlySalesListReportInfo?.totalMonthlySales}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export { MonthReport }