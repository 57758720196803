/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import { getPlanReport, PlanReportResponse } from '../core/_requests'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const CustomersReport: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [customerPlanReportInfo, setCustomerPlanReportInfo] = useState<{userPlanReportInfo: PlanReportResponse[], customerSubscriptionCount: number}>()

  const getCustomerPlanReport = async () => {
    const planReport = await getPlanReport()
    
    console.log('planReport', planReport)
    setCustomerPlanReportInfo(planReport)
  }

  useEffect(() => {
    getCustomerPlanReport()
  }, [])
  
  useEffect(() => {

    if (!chartRef.current) {
      return
    }

    const countList = []
    const monthList = []
    console.log('customerPlanReportInfo :: ', customerPlanReportInfo)
    if (customerPlanReportInfo) {
        countList.push(...customerPlanReportInfo.userPlanReportInfo.map(customerPlanReport => customerPlanReport.count))
        monthList.push(...customerPlanReportInfo.userPlanReportInfo.map(customerPlanReport => customerPlanReport.month))
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, countList, monthList))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, customerPlanReportInfo])

  return (
    <div className={`card ${className} m-1`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className='flex-grow-1 card-p pb-0'>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                Customer Subscription
              </a>

              <div className='text-muted fs-7 fw-bold'>Customer subscription report</div>
            </div>

            <div className={`fw-bolder fs-3 text-${chartColor}`}>{customerPlanReportInfo?.customerSubscriptionCount}</div>
          </div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, countList: number[], monthList: string[]): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: countList//[10,20,30,60,10],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: monthList, //['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' users'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {CustomersReport}
