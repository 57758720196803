import { Column } from 'react-table'
import { UserSurveyInfoCell } from './UserSurveyInfoCell'
import { UserSurveyActionsCell } from './UserSurveyActionsCell'
// import { SurveyManagementSelectionCell } from './SurveyManagementSelectionCell'
import { UserSurveyCustomHeader } from './UserSurveyCustomHeader'
// import { SurveyManagementSelectionHeader } from './SurveyManagementSelectionHeader'
import { UserSurvey } from '../../core/_models'
// import { CustomerStatusCell } from './SurveyManagementStatusCell'
import { UserSurveyCreatedAtCell } from './UserSurveyCreatedAtCell'
// import { UserSurveySubmissionCell } from './UserSurveySubmissionCell'

const surveyManagementColumns: ReadonlyArray<Column<UserSurvey>> = [
  // {
  //   Header: (props) => <SurveyManagementSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <SurveyManagementSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserSurveyCustomHeader tableProps={props} title='title' className='min-w-125px' />,
    id: 'title',
    Cell: ({ ...props }) => <UserSurveyInfoCell survey={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserSurveyCustomHeader tableProps={props} title='created At' className='min-w-125px' />,
    id: 'createdAt',
    Cell: ({ ...props }) => <UserSurveyCreatedAtCell createdAt={props.data[props.row.index].createdAt} />,
  },
  /* {
    Header: (props) => <UserSurveyCustomHeader tableProps={props} title='submission count' className='min-w-125px' />,
    id: 'updatedAt',
    Cell: ({ ...props }) => <UserSurveySubmissionCell updatedAt={props.data[props.row.index].updatedAt} />,
  }, */
  {
    Header: (props) => (
      <UserSurveyCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <UserSurveyActionsCell id={props.data[props.row.index].id} />,
  },
]

export { surveyManagementColumns }
