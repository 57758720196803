import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {useQueryResponseData, useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {callLogsColumns} from '../columns/_columns'
import {CallLog} from '../../core/_models'
import {CallLogsListLoading} from '../../components/loading/CallLogsListLoading'
import {CallLogsListPagination} from '../../components/pagination/CallLogsListPagination'
import {KTCardBody} from '../../../../../../../_metronic/helpers'
import { CustomRow } from '../columns/CustomRow'

const CallLogsTable = () => {
  const callLogs = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => callLogs, [callLogs])
  const columns = useMemo(() => callLogsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  console.log('data', data)
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<CallLog>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<CallLog>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CallLogsListPagination />
      {isLoading && <CallLogsListLoading />}
    </KTCardBody>
  )
}

export {CallLogsTable}
