import {Column} from 'react-table'
import {SubscriptionsPaymentsInfoCell} from './SubscriptionsPaymentsInfoCell'
import {SubscriptionsPaymentsActionsCell} from './SubscriptionsPaymentsActionsCell'
// import {SubscriptionsPaymentsSelectionCell} from './SubscriptionsPaymentsSelectionCell'
import {SubscriptionsPaymentsCustomHeader} from './SubscriptionsPaymentsCustomHeader'
// import {SubscriptionsPaymentsSelectionHeader} from './SubscriptionsPaymentsSelectionHeader'
import {Subscription} from '../../core/_models'
import { SubscriptionsPaymentsExpireDateCell } from './SubscriptionsPaymentsExpireDateCell'
import { SubscriptionsPaymentsTransactionDateCell } from './SubscriptionsPaymentsTransactionDateCell'
import { SubscriptionsPaymentsInfoPlanCell } from './SubscriptionsPaymentsInfoPlanCell'
import { SubscriptionsPaymentsAmountDateCell } from './SubscriptionsPaymentsAmountDateCell'

const subscriptionsPaymentsColumns: ReadonlyArray<Column<Subscription>> = [
  // {
  //   Header: (props) => <SubscriptionsPaymentsSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <SubscriptionsPaymentsSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <SubscriptionsPaymentsCustomHeader tableProps={props} title='Plan' className='min-w-125px' />,
    id: 'plan_name',
    Cell: ({...props}) => <SubscriptionsPaymentsInfoPlanCell plan={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <SubscriptionsPaymentsCustomHeader tableProps={props} title='Order Id' className='min-w-125px' />,
    id: 'order_id',
    Cell: ({...props}) => <SubscriptionsPaymentsInfoCell order_id={props.data[props.row.index].order_id} />,
  },

  {
    Header: (props) => <SubscriptionsPaymentsCustomHeader tableProps={props} title='Expire Date' className='min-w-125px' />,
    id: 'expire_date',
    Cell: ({...props}) => <SubscriptionsPaymentsExpireDateCell expire_date={props.data[props.row.index].expire_date} />,
  },

  {
    Header: (props) => <SubscriptionsPaymentsCustomHeader tableProps={props} title='Transaction Date' className='min-w-125px' />,
    id: 'transaction_date',
    Cell: ({...props}) => <SubscriptionsPaymentsTransactionDateCell transaction_date={props.data[props.row.index].transaction_date} />,
  },

  {
    Header: (props) => <SubscriptionsPaymentsCustomHeader tableProps={props} title='amount' className='min-w-125px' />,
    id: 'amount',
    Cell: ({...props}) => <SubscriptionsPaymentsAmountDateCell plan={props.data[props.row.index]} />,
  },
  
  // {
  //   Header: (props) => (
  //     <EducationCustomHeader tableProps={props} title='Country' className='min-w-125px' />
  //   ),
  //   id: 'country_name',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].country.country_name} />,
  // },


  // {
  //   Header: (props) => (
  //     <EducationCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  {
    Header: (props) => (
      <SubscriptionsPaymentsCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <SubscriptionsPaymentsActionsCell id={props.data[props.row.index].id} />,
  },
]

export {subscriptionsPaymentsColumns}
