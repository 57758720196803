import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, {useEffect, useRef, useState} from 'react'

import { getUserReport, UserReportResponse,getAllUserReport,getTodaySubscription, getTotalCustomerSubscriptionReport, getFemaleGenderReport, TotalgenderResponse, getMaleGenderReport, TotalCustomerSubscriptionResponse, TodaySubscriptionResponse,} from '../core/_requests' 

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const ActiveUsers: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [userListReportInfo, setUserActiveReportInfo] = useState<{userListReportInfo: UserReportResponse[], totalRecordCount: number}>()

  const getUserPlanReport = async () => {
    const userReport = await getUserReport()
    
    console.log('userReport', userReport)
    userReport && setUserActiveReportInfo(userReport)
  }

useEffect(() => {
  getUserPlanReport()
}, [])

const [userTodayListReportInfo, setTodaySubscriptionReportInfo] = useState<{userTodayListReportInfo: TodaySubscriptionResponse[], todaydate: number}>()
  
    const getTodayPlanReport = async () => {
      const todayReport = await getTodaySubscription()
      
      console.log('todayReport', todayReport)
      todayReport && setTodaySubscriptionReportInfo(todayReport)
    }

useEffect(() => {
  getTodayPlanReport()
  }, [])

const [totalcustomerSubscriptionListReportInfo, setTotalCustomerSubscriptionReportInfo] = useState<{totalcustomerSubscriptionListReportInfo: TotalCustomerSubscriptionResponse[], totalCustomerSubscriptionCount: number}>()
  
    const getTotalCustomerPlanReport = async () => {
      const toatlCustomerReport = await getTotalCustomerSubscriptionReport()
      
      console.log('toatlCustomerReport', toatlCustomerReport)
      toatlCustomerReport && setTotalCustomerSubscriptionReportInfo(toatlCustomerReport)
    }

useEffect(() => {
    getTotalCustomerPlanReport()
  }, [])
  

  const [FemaleGenderListReportInfo, setFemaleReportInfo] = useState<{FemaleGenderListReportInfo: TotalgenderResponse[], femaleCount: number}>()
  
    const getFemalePlanReport = async () => {
      const toatalFemaleReport = await getFemaleGenderReport()
      
      console.log('toatalFemaleReport', toatalFemaleReport)
      toatalFemaleReport && setFemaleReportInfo(toatalFemaleReport)
    }

useEffect(() => {
  getFemalePlanReport()
  }, [])

  const [MaleGenderListReportInfo, setMaleReportInfo] = useState<{MaleGenderListReportInfo: TotalgenderResponse[], maleCount: number}>()
  
    const getMalePlanReport = async () => {
      const toatalMaleReport = await getMaleGenderReport()
      
      console.log('toatalMaleReport', toatalMaleReport)
      toatalMaleReport && setMaleReportInfo(toatalMaleReport)
    }

useEffect(() => {
  getMalePlanReport()
  }, [])

  const [AllUserListReportInfo, setAllUserReportInfo] = useState<{AllUserListReportInfo: UserReportResponse[], allUserCount: number}>()
  
    const getAllUserPlanReport = async () => {
      const toatalUserReport = await getAllUserReport()
      
      console.log('toatalUserReport', toatalUserReport)
      toatalUserReport && setAllUserReportInfo(toatalUserReport)
    }

useEffect(() => {
  getAllUserPlanReport()
  }, [])
  

useEffect(() => {

  if (!chartRef.current) {
    return
  }

  const countList = []
  const monthList = []
  console.log('userListReportInfo :: ', userListReportInfo)
  if (userListReportInfo) {
      countList.push(...userListReportInfo.userListReportInfo.map(userListReportInfo => userListReportInfo.count))
      monthList.push(...userListReportInfo.userListReportInfo.map(userListReportInfo => userListReportInfo.count))
  }
  
}, [chartRef, userListReportInfo])

  return (
    <Container className='w-lg-980px bg-white rounded shadow-sm  p-lg-15 mx-auto' >
      <div className='d-flex flex-stack flex-wrap'>
        <div className={`card ${className}`}>
        <div className='me-2'>
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            User Details
          </a>
        </div>
        </div>
      </div>
      <div  style={{ border: '1px solid white'} }>
      <Row className='border border border-gray-400 border-dashed rounded bg-white rounded shadow-sm'>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Active User</h2>
              <div className='mt-6'>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{userListReportInfo?.totalRecordCount}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Today Subscriptions</h2>
              <div className=''>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{userTodayListReportInfo?.todaydate}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Male Users</h2>
              <div className='mt-6'>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{MaleGenderListReportInfo?.maleCount}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='border border border-gray-400 border-dashed rounded bg-white rounded shadow-sm'>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Total User</h2>
              <div className='mt-6'>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{AllUserListReportInfo?.allUserCount}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Total Subscriptions</h2>
              <div className=''>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{totalcustomerSubscriptionListReportInfo?.totalCustomerSubscriptionCount}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} md={4} sm={6} xxl={12}>
          <div className=' p-8'>
            <div className='border border-gray-400 border-dashed rounded bg-white rounded shadow-sm bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
              <h2 className="text-center">Female Users</h2>
              <div className='mt-6'>
              <div style={{color:'#009ef7'}} className={`fw-bolder fs-3 text-${className} text-center`}>{FemaleGenderListReportInfo?.femaleCount}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      </div>
    </Container>
  );
}

export { ActiveUsers }
