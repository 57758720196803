import axios, {AxiosResponse} from 'axios'
import {CallLogsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CALL_LOGS_LIST_URL = `${API_URL}/user/call_logs`
// const EXPORT_INVOICE_URL = `${API_URL}/user/call_logs/downloadPDF`

const getCallLogsByUserId = (user_id: string, query: string): Promise<CallLogsQueryResponse> => {
  return axios
    .get(`${CALL_LOGS_LIST_URL}/${user_id}?${query}`)
    .then((response: AxiosResponse<CallLogsQueryResponse>) => response.data)
}

export {getCallLogsByUserId}
