import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getTotalCustomerSubscriptionReport, getRevenueReport, TotalCustomerSubscriptionResponse, TotalRevenueResponse } from '../core/_requests'

type Props = {
    className: string
    chartColor: string
    chartHeight: string
}

const Subscriptions: React.FC<Props> = ({ className }) => {
    const [totalcustomerSubscriptionListReportInfo, setTotalCustomerSubscriptionReportInfo] = useState<{ totalcustomerSubscriptionListReportInfo: TotalCustomerSubscriptionResponse[], totalCustomerSubscriptionCount: number }>()
    console.log('totalcustomerSubscriptionListReportInfo>>>>>>>', totalcustomerSubscriptionListReportInfo);

    const getTotalCustomerPlanReport = async () => {
        const toatlCustomerReport = await getTotalCustomerSubscriptionReport()

        console.log('toatlCustomerReport', toatlCustomerReport)
        toatlCustomerReport && setTotalCustomerSubscriptionReportInfo(toatlCustomerReport)
    }

    useEffect(() => {
        getTotalCustomerPlanReport()
    }, [])

    const [RevenueListReportInfo, setRevenueReportInfo] = useState<{ RevenueListReportInfo: TotalRevenueResponse[], totalSales: number }>()

    const getRevenuePlanReport = async () => {
        const totalRevenueReport = await getRevenueReport()

        console.log('totalRevenueReport', totalRevenueReport)
        totalRevenueReport && setRevenueReportInfo(totalRevenueReport)
    }

    useEffect(() => {
        getRevenuePlanReport()
    }, [])


    return (
        <Container className='w-lg-980px bg-white rounded shadow-sm m-5 p-lg-15 mx-auto'>
            <div className={`card ${className}`}>
                <div className='me-2'>
                    <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                        Subscriptions
                    </a>
                </div>
            </div>
            <Row className='border border border-gray-400 border-dashed rounded bg-white rounded shadow-sm ' style={{ border: '1px solid white', minHeight: "150px", maxWidth: '420px' }}>
                <Col xl={6} md={6} sm={12} xxl={12}>
                    <div className='border p-8'>
                        <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
                            <h2 className="text-center">Total Subscription</h2>
                            <div className=''>
                                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>{totalcustomerSubscriptionListReportInfo?.totalCustomerSubscriptionCount}</div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={6} md={6} sm={12} xxl={12}>
                    <div className='border p-8'>
                        <div className='border border-gray-400 border-dashed rounded bg-light-primary' style={{ border: '2px solid blue', minHeight: "100px", maxWidth: '150px', }}>
                            <h2 className="text-center">Total Revenue</h2>
                            <div className=''>
                                <div style={{ color: '#009ef7' }} className={`fw-bolder fs-3 text-${className} text-center`}>${RevenueListReportInfo?.totalSales}</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export { Subscriptions }